import { useEffect, useState } from "react";

const Footer = () => {

    const [title, setTitle] = useState(null);
    const [description, setDescription] = useState(null);

    useEffect(() => {

        try {
            fetch('https://chaletdellavalle.hycepanel.it/api/disclaimer?fields[0]=titolo&fields[1]=descrizione', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(response => response.json())
                .then(data => {
                    //console.log(data);
                    setTitle(data.data.attributes.titolo);
                    setDescription(data.data.attributes.descrizione);
                })
        } catch (error) {
            setTitle("");
            setDescription("");
            console.error(error)
        }

    }, [])

    return (
        <div className="footer">
            <h1 className="disclaimer-title">{title}</h1>
            <h2 className="disclaimer">{description}</h2>
        </div>
    );

}

export default Footer;