const Sezione = ({ sezione }) => {

    sezione.attributes.pietanze.data.sort((a, b) => a.attributes.ordine - b.attributes.ordine);

    let img;
    if (sezione.attributes.immagine.data != null) {
        img = 'https://chaletdellavalle.hycepanel.it' + sezione.attributes.immagine.data.attributes.url;
    }
    else {
        img = null
    }

    return (
        <div className="sezione script">
            <h3 style={{
                fontSize: "15pt",
                margin: "2em 0.5em 0 0.5em"
            }}>{sezione.attributes.nome}</h3>
            <div style={{
                display: "flex",
                flexDirection: "column"
            }}>

                {sezione.attributes.pietanze.data.map((pietanza) => {
                    return (
                        <div className="pietanza" key={pietanza.id}>
                            <p className="descrizione">{pietanza.attributes.descrizione && ("• " + pietanza.attributes.descrizione)}</p>
                            <p className="prezzo">{pietanza.attributes.prezzo && ("€ " + pietanza.attributes.prezzo)}</p>
                        </div>
                    )
                })}


                {sezione.attributes.nota &&
                    <h3 className="print" style={{
                        fontSize: "8pt",
                        fontWeight: "bold",
                        textAlign: "center",
                        margin: "2em 1em 1.5em 1em"
                    }}>{sezione.attributes.nota}</h3>}


                {img && <img className="banner" style={{ marginTop: "3em" }} src={img} alt="" />}

            </div>
        </div>
    );
}

export default Sezione;