import { useEffect, useState } from "react";

const Hero = () => {

    const [logo, setLogo] = useState(null);
    const [banner, setBanner] = useState(null);
    const [title, setTitle] = useState(null);
    const [subtitle, setSubtitle] = useState(null);

    useEffect(() => {


        /*const qs = require('qs');
        const query = qs.stringify({
            populate: ['logo', 'banner'],
            fields: ['titolo', 'sottotitolo'],
          }, {
            encodeValuesOnly: true,
          });*/



        try {
            fetch('https://chaletdellavalle.hycepanel.it/api/titolo?populate[0]=logo&populate[1]=banner&fields[0]=titolo&fields[1]=sottotitolo', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(response => response.json())
                .then(data => {
                    //console.log(data);
                    setTitle(data.data.attributes.titolo);
                    setSubtitle(data.data.attributes.sottotitolo);
                    setBanner('https://chaletdellavalle.hycepanel.it' + data.data.attributes.banner.data.attributes.url);
                    setLogo('https://chaletdellavalle.hycepanel.it' + data.data.attributes.logo.data.attributes.url);
                })
        } catch (error) {
            setTitle("Si è verificato un errore nel caricamento del menù");
            setSubtitle("Si prega di riprovare più tardi");
            setBanner(null);
            setLogo(null);
            console.error(error)
        }

    }, [])

    return (
        <div className="hero">
            {logo && <img className="logo" src={logo} alt="Chalet della valle" />}
            {banner && <img className="banner" src={banner} alt="Chalet della valle" />}
            <h1 className="title">{title}</h1>
            <h2 className="subtitle">{subtitle}</h2>
        </div>
    );
}

export default Hero;