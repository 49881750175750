import Sezione from "./sezione";

const SectionsList = ({sezioni}) => {

    return ( 
        <div className="section">
            


            {sezioni.map((sezione) => { return (
                <div key={sezione.id}>
                    <Sezione sezione={sezione}></Sezione>
                </div>
            )})}


            





        </div>
     );
}
 
export default SectionsList;