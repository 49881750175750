import { useEffect, useState } from "react";
import Footer from "./components/footer";
import Hero from "./components/hero";
import SectionsList from "./components/sectionsList";

function App() {

    const [sezioni, setSezioni] = useState(null);
    const [error, setError] = useState(false);

    useEffect(() => {

        try {
            fetch('https://chaletdellavalle.hycepanel.it/api/sezioni?sort[0]=ordine&sort[1]=ordine&populate=*', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(response => response.json())
                .then(data => {
                    //console.log(data.data);
                    setSezioni(data.data);
                })
        } catch (error) {
            console.error(error)
            setError(true);
        }

    }, [])

    return (
        <div className="App">
            <Hero></Hero>
            {sezioni && <SectionsList sezioni={sezioni}></SectionsList>}
            {error && <h4>Si è verificato un errore nel caricamento del menù, si prega di riprovare più tardi</h4>}
            <Footer></Footer>
        </div>
    );
}

export default App;
